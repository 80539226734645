import React, { FC, useEffect, useRef, RefObject } from 'react';
import { ColDef, GridOptions, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Theme } from '@material-ui/core';
import { CountryModel, VIEW_MODE, AuditHistory, baseApiPath } from '@wings/shared';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  COUNTRY_FILTERS,
  CountryModuleSecurity,
  COUNTRY_AUDIT_MODULES,
  updatedBackNavigation,
  updateCountrySidebarOptions,
  CountryStore,
  RegionStore,
  SettingsStore,
} from '../Shared';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { forkJoin, Observable } from 'rxjs';
import {
  AccessLevelModel,
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  SourceTypeModel,
  UIStore,
  Utilities,
  ViewPermission,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import {
  CustomAgGridReact,
  IActionMenuItem,
  useAgGrid,
  useGridState,
  agGridUtilities,
} from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useParams } from 'react-router';
import { countryGridFilters } from './fields';

interface Props {
  countryStore?: CountryStore;
  settingsStore?: SettingsStore;
  regionStore?: RegionStore;
  sidebarStore?: typeof SidebarStore;
  theme?: Theme;
}

const CoreModule: FC<Props> = ({ ...props }) => {
  const unsubscribe = useUnsubscribe();
  const params = useParams();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const gridState = useGridState();
  const agGrid = useAgGrid<COUNTRY_FILTERS, CountryModel>(countryGridFilters, gridState);
  const _sidebarStore = props.sidebarStore as typeof SidebarStore;
  const _settingsStore = props.settingsStore as SettingsStore;
  const _countryStore = props.countryStore as CountryStore;

  /* istanbul ignore next */
  useEffect(() => {
    _sidebarStore?.setNavLinks(updateCountrySidebarOptions('Countries'), 'countries');
    loadInitialData();
    agGrid.filtersApi.onAdvanceFilterChange$.subscribe(() => loadInitialData());
  }, []);

  /* istanbul ignore next */
  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    forkJoin([ loadCountries(pageRequest), _settingsStore?.getAccessLevels(), _settingsStore?.getSourceTypes() ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  };

  /* istanbul ignore next */
  const filterCollection = (): IAPIGridRequest | null => {
    const { continentId } = params;
    if (continentId) {
      return {
        filterCollection: JSON.stringify([{ propertyName: 'Continent.ContinentId', propertyValue: continentId }]),
      };
    }
    return null;
  };

  /* istanbul ignore next */
  const loadCountries = (pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<CountryModel>> => {
    if (Boolean(Array.from(gridState.columFilters).length && searchHeaderRef.current?.searchValue.length)) {
      searchHeaderRef.current?.resetInputs();
    }

    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: gridState.pagination.pageSize,
      ...pageRequest,
      ...filterCollection(),
      ...agGrid.filtersApi.getSearchFilters(
        searchHeaderRef.current?.searchValue as string,
        searchHeaderRef.current?.selectedOption
      ),
      ...agGrid.filtersApi.gridSortFilters(),
      ...agGrid.filtersApi.getAdvancedSearchFilters(),
    };

    return (_countryStore as CountryStore).getCountries(request).pipe(
      takeUntil(unsubscribe.destroy$),
      tap(countries => {
        gridState.setGridData(countries.results);
        gridState.setPagination(new GridPagination({ ...countries }));
        agGrid.filtersApi.gridAdvancedSearchFilterApplied();
      })
    );
  };

  const rightContent = () => {
    return (
      <ViewPermission hasPermission={CountryModuleSecurity.isEditable}>
        <CustomLinkButton variant="contained" startIcon={<AddIcon />} to="upsert/new" title="Add Country" />
      </ViewPermission>
    );
  };

  const backButton = () => {
    const { continentId } = params;
    if (!continentId) return null;
    const { updatedBackNavLink, backNavTitle } = updatedBackNavigation();
    return <CustomLinkButton to={updatedBackNavLink} title={backNavTitle} startIcon={<ArrowBack />} />;
  };

  /* istanbul ignore next */
  const actionMenus = (): IActionMenuItem[] => {
    return [
      {
        title: 'Edit',
        isHidden: !CountryModuleSecurity.isEditable,
        action: GRID_ACTIONS.EDIT,
        to: node => `upsert/${node.data.id}/${VIEW_MODE.EDIT.toLocaleLowerCase()}`,
      },
      {
        title: 'Details',
        action: GRID_ACTIONS.DETAILS,
        to: node => `upsert/${node.data.id}/${VIEW_MODE.DETAILS.toLocaleLowerCase()}`,
      },
      { title: 'Audit', action: GRID_ACTIONS.AUDIT },
      {
        title: 'View States',
        action: GRID_ACTIONS.VIEW,
        to: node => `${node.data.id}/states`,
      },
      {
        title: 'View Islands',
        action: GRID_ACTIONS.VIEW,
        to: node => `${node.data.id}/islands`,
      },
    ];
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'ISO2 code',
      field: 'isO2Code',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('isO2Code', 2),
    },
    {
      headerName: 'ISO3 code',
      field: 'isO3Code',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('isO3Code', 2),
    },
    {
      headerName: 'Official Name',
      field: 'officialName',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('officialName', 2),
    },
    {
      headerName: 'Common Name',
      field: 'commonName',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('commonName', 2),
    },
    {
      headerName: 'Is Territory',
      field: 'isTerritory',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: { readOnly: true },
    },
    {
      headerName: 'Sovereign Country',
      field: 'sovereignCountry',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('sovereignCountry', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data?.sovereignCountry?.name || '',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name || '',
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusRenderer',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('status', 2, 'start'),
      filterValueGetter: ({ data }: ValueGetterParams) => data?.status?.name || '',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Access Level',
      field: 'accessLevel',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('accessLevel', 2),
      comparator: (current: AccessLevelModel, next: AccessLevelModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Source Type',
      field: 'sourceType',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('sourceType', 2),
      comparator: (current: SourceTypeModel, next: SourceTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    ...agGrid.auditFieldsWithAdvanceFilter(gridState.isRowEditing),
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      maxWidth: 210,
      suppressSizeToFit: true,
      sortable: false,
      filter: false,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => actionMenus(),
        onAction: (action: GRID_ACTIONS, rowIndex: number, node: RowNode) => {
          if (action === GRID_ACTIONS.AUDIT) {
            const model: CountryModel = agGrid._getTableItem(rowIndex);
            ModalStore.open(
              <AuditHistory
                title={model.officialName || model.commonName}
                entityId={model.id}
                entityType={COUNTRY_AUDIT_MODULES.COUNTRY}
                baseUrl={baseApiPath.countries}
              />
            );
          }
        },
      },
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {},
      columnDefs: columnDefs,
    });

    return {
      ...baseOptions,
      pagination: false,
      isExternalFilterPresent: () => false,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,

      onFilterChanged: () => Array.from(gridState.columFilters).length === 0 && loadInitialData(),
      // onFilterModified: (filterModified: FilterModifiedEvent) => this.onGridApiFilterModified(filterModified),
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadInitialData({ pageNumber: 1 });
      },
    };
  };
  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        backButton={backButton()}
        selectInputs={[ agGridUtilities.createSelectOption(COUNTRY_FILTERS, COUNTRY_FILTERS.COMMONNAME) ]}
        onFilterChange={isInitEvent =>
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 })
        }
        rightContent={rightContent}
        disableControls={Boolean(Array.from(gridState.columFilters).length) || gridState.isRowEditing}
        onExpandCollapse={agGrid.autoSizeColumns}
        onResetFilterClick={() => {
          agGrid.cancelEditing(0);
          agGrid.filtersApi.resetColumnFilters();
        }}
      />
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={loadInitialData}
      />
    </>
  );
};

export default inject('countryStore', 'settingsStore', 'regionStore', 'sidebarStore')(observer(CoreModule));
