import React, { FC, useRef, ReactNode, useEffect, RefObject } from 'react';
import { inject, observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { forkJoin, Observable } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams, RowNode } from 'ag-grid-community';
import { AuditHistory, CountryModel, GridPagination, ModelStatusOptions, baseApiPath } from '@wings/shared';
import { CustomAgGridReact, agGridUtilities, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { AlertStore } from '@uvgo-shared/alert';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import {
  ViewPermission,
  GRID_ACTIONS,
  UIStore,
  AccessLevelModel,
  Utilities,
  ISelectOption,
  SourceTypeModel,
  IAPIGridRequest,
  regex,
  IClasses,
  IAPIPageResponse,
} from '@wings-shared/core';
import { Tooltip, Chip } from '@material-ui/core';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { useUnsubscribe } from '@wings-shared/hooks';
import { SearchHeaderV2, ISearchHeaderRef } from '@wings-shared/form-controls';
import {
  SettingsStore,
  CountryStore,
  CountryModuleSecurity,
  COUNTRY_AUDIT_MODULES,
  FIRStore,
  FIRModel,
} from '../Shared';
import { FIR_FILTERS } from './Enums';
import { useStyles } from './FIRsOwn.styles';

interface Props {
  firStore?: FIRStore;
  countryStore?: CountryStore;
  settingsStore?: SettingsStore;
  showSearchHeader?: boolean;
  countryId?: number;
}

const FIRsOwn: FC<Props> = ({
  firStore,
  countryStore,
  settingsStore,
  countryId,
  showSearchHeader = true,
}) => {
  const unsubscribe = useUnsubscribe();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const gridState = useGridState();
  const agGrid = useAgGrid<FIR_FILTERS, FIRModel>([], gridState);
  const classes = useStyles();
  const _settingsStore = settingsStore as SettingsStore;
  const _countryStore = countryStore as CountryStore;
  const _firStore = firStore as FIRStore;

  // Load Data on Mount
  /* istanbul ignore next */
  useEffect(() => {
    loadInitialData();
  }, []);

  /* istanbul ignore next */
  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    forkJoin([
      _countryStore.getCountries(),
      _settingsStore.getSourceTypes(),
      _settingsStore.getAccessLevels(),
      loadFIRsOwned(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  };

  /* istanbul ignore next */
  const filterCollection = (): IAPIGridRequest | null => {
    if (countryId) {
      return {
        filterCollection: JSON.stringify([
          { propertyName: 'FIRControllingCountries.CountryId', propertyValue: countryId },
          { propertyName: 'FIRLandmassCountries.CountryId', propertyValue: countryId, operator: 'or' },
        ]),
      };
    }
    return null;
  };

  /* istanbul ignore next */
  const loadFIRsOwned = (pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<FIRModel>> => {
    const request: IAPIGridRequest = {
      pageSize: 0,
      ...pageRequest,
      ...filterCollection(),
    };
    return _firStore.getFIRsOwned(request).pipe(
      takeUntil(unsubscribe.destroy$),
      tap(FIR => {
        gridState.data = FIR.results;
        gridState.pagination = new GridPagination({ ...FIR });
      })
    );
  };

  // Check if FIR already exists
  const isAlreadyExists = (id: number, rowIndex: number): boolean => {
    const isExists = agGrid._isAlreadyExists([ 'code', 'name' ], id, rowIndex);
    if (isExists) {
      agGrid.showAlert('Code and Name should be unique.', 'FIRsOwnAlertMessage');
    }
    return isExists;
  };

  /* istanbul ignore next */
  const upsertFIRs = (rowIndex: number): void => {
    const data: FIRModel = agGrid._getTableItem(rowIndex);
    if (isAlreadyExists(data.id, rowIndex)) {
      return;
    }
    gridState.gridApi.stopEditing();
    const hasInvalidRowData: boolean = Utilities.hasInvalidRowData(gridState.gridApi);

    if (hasInvalidRowData) {
      AlertStore.info('Please fill all required fields');
      return;
    }
    UIStore.setPageLoader(true);
    _firStore
      .upsertFIRControllingCountry(data)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: FIRModel) => agGrid._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        agGrid._startEditingCell(rowIndex, columnDefs[2].field || '');
        break;
      case GRID_ACTIONS.AUDIT:
        const model: FIRModel = agGrid._getTableItem(rowIndex);
        ModalStore.open(
          <AuditHistory
            title={model.name}
            entityId={model.id}
            entityType={COUNTRY_AUDIT_MODULES.FIR}
            baseUrl={baseApiPath.countries}
          />
        );
        break;
      case GRID_ACTIONS.SAVE:
        upsertFIRs(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        agGrid.cancelEditing(rowIndex);
        break;
    }
  };

  /* istanbul ignore next */
  const viewRenderer = (
    countryChips: CountryModel[],
    getTagProps?: AutocompleteGetTagProps,
    isReadMode?: boolean
  ): ReactNode => {
    const numTags = countryChips.length;
    const limitTags = 1;
    const chipsList = isReadMode ? countryChips : [ ...countryChips ].slice(0, limitTags);
    return (
      <div>
        {Utilities.customArraySort(chipsList, 'isO2Code').map((country: CountryModel, index) => (
          <Tooltip title={country.commonName || ''} key={index}>
            <Chip
              classes={{ root: (classes as IClasses).root }}
              key={country.id}
              label={country.isO2Code}
              {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
            />
          </Tooltip>
        ))}
        {numTags > limitTags && !isReadMode && ` +${numTags - limitTags} more`}
      </div>
    );
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Code',
      field: 'code',
      cellEditorParams: {
        isRequired: true,
        rules: `required|string|regex:${regex.alphabetsWithoutSpaces}|size:4`,
      },
    },
    {
      headerName: 'Name',
      field: 'name',
      cellEditorParams: {
        isRequired: true,
        rules: 'required|string|between:1,100',
      },
    },
    {
      headerName: 'Controlling Countries',
      field: 'firControllingCountries',
      cellEditor: 'customAutoComplete',
      cellRenderer: 'viewRenderer',
      sortable: false,
      filter: false,
      minWidth: 250,
      filterValueGetter: ({ data }: ValueGetterParams) => data.firControllingCountries,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) =>
          viewRenderer(node.data?.firControllingCountries, null, true),
      },
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Select Country',
        multiSelect: true,
        disableCloseOnSelect: true,
        valueGetter: (selectedOptions: CountryModel[]) => selectedOptions,
        renderTags: (values: CountryModel[], getTagProps: AutocompleteGetTagProps) => viewRenderer(values, getTagProps),
        getAutoCompleteOptions: () => _countryStore.countries,
      },
    },
    {
      headerName: 'Landmass Countries',
      field: 'firLandmassCountries',
      cellEditor: 'customAutoComplete',
      cellRenderer: 'viewRenderer',
      sortable: false,
      filter: false,
      minWidth: 250,
      filterValueGetter: ({ data }: ValueGetterParams) => data.firLandmassCountries,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) =>
          viewRenderer(node.data?.firLandmassCountries, null, true),
      },
      cellEditorParams: {
        placeHolder: 'Select Country',
        displayKey: 'name',
        multiSelect: true,
        disableCloseOnSelect: true,
        valueGetter: (selectedOptions: CountryModel[]) => selectedOptions,
        renderTags: (values: CountryModel[], getTagProps: AutocompleteGetTagProps) => viewRenderer(values, getTagProps),
        getAutoCompleteOptions: () => _countryStore.countries,
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusRenderer',
      cellEditor: 'customAutoComplete',
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Status',
        getAutoCompleteOptions: () => ModelStatusOptions,
        valueGetter: (option: ISelectOption) => option,
      },
    },
    {
      headerName: 'Access Level',
      field: 'accessLevel',
      cellEditor: 'customAutoComplete',
      comparator: (current: AccessLevelModel, next: AccessLevelModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Access Level',
        getAutoCompleteOptions: () => _settingsStore.accessLevels,
        valueGetter: (option: ISelectOption) => option,
      },
    },
    {
      headerName: 'Source Type',
      field: 'sourceType',
      cellEditor: 'customAutoComplete',
      comparator: (current: SourceTypeModel, next: SourceTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        placeHolder: 'Source Type',
        getAutoCompleteOptions: () => _settingsStore.sourceTypes,
        valueGetter: (option: ISelectOption) => option,
      },
    },
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      ...agGrid.actionColumn({
        cellRendererParams: {
          isActionMenu: true,
          onAction: (action: GRID_ACTIONS, rowIndex: number) => gridActions(action, rowIndex),
          actionMenus: () => [
            {
              title: 'Edit',
              isHidden: !CountryModuleSecurity.isEditable || Boolean(countryId),
              action: GRID_ACTIONS.EDIT,
            },
            {
              title: 'Audit',
              action: GRID_ACTIONS.AUDIT,
            },
          ],
        },
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {
        onInputChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
        onDropDownChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
      },
      columnDefs,
      isEditable: CountryModuleSecurity.isEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => gridState.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      suppressClickEdit: Boolean(countryId),
      isExternalFilterPresent: () => searchHeaderRef.current?.hasSearchValue || false,
      doesExternalFilterPass: node => {
        const searchHeader = searchHeaderRef.current;
        const { name, code, firControllingCountries, firLandmassCountries, id } = node.data as FIRModel;
        if (!searchHeader) {
          return false;
        }
        return (
          !id ||
          agGrid.isFilterPass(
            {
              [FIR_FILTERS.NAME]: name,
              [FIR_FILTERS.CODE]: code,
              [FIR_FILTERS.CONTROLLING_COUNTRY]: firControllingCountries.map(c => c.officialName),
              [FIR_FILTERS.OVER_LANDMASS_COUNTRY]: firLandmassCountries.map(c => c.officialName),
            },
            searchHeader.searchValue,
            searchHeader.selectedOption
          )
        );
      },
    };
  };

  /* istanbul ignore next */
  const addFir = () => {
    const fir = new FIRModel({ id: 0 });
    agGrid.addNewItems([ fir ], { startEditing: false, colKey: 'code' });
    gridState.setHasError(true);
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={CountryModuleSecurity.isEditable}>
        <PrimaryButton
          variant="contained"
          startIcon={<AddIcon />}
          disabled={gridState.isRowEditing || UIStore.pageLoading}
          onClick={addFir}
        >
          Add FIRs
        </PrimaryButton>
      </ViewPermission>
    );
  };

  return (
    <>
      {showSearchHeader && (
        <SearchHeaderV2
          ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
          onExpandCollapse={agGrid.autoSizeColumns}
          selectInputs={[ 
            agGridUtilities.createSelectOption([ FIR_FILTERS.NAME, FIR_FILTERS.CODE ], FIR_FILTERS.NAME) 
          ]}
          rightContent={rightContent}
          onFilterChange={() => gridState.gridApi.onFilterChanged()}
          disableControls={gridState.isRowEditing}
        />
      )}
      <CustomAgGridReact isRowEditing={gridState.isRowEditing} rowData={gridState.data} gridOptions={gridOptions()} />
    </>
  );
};

export default inject('firStore', 'countryStore', 'settingsStore')(observer(FIRsOwn));
